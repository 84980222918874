import { get } from 'lodash';
import {
  POST_PUBLISH,
  POST_UPDATE,
} from '../../../common/components/message/message-types';
import { PUBLISH_POST_SUCCESS } from '../../../common/actions/publish-post-types';

export default {
  [PUBLISH_POST_SUCCESS]: (action) =>
    get(action, 'meta.isUpdated') ? POST_UPDATE : POST_PUBLISH,
};
